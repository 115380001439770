import { NativeObjectTypes } from 'src/lib/objects'

import { EnumCellRenderer } from '../Objects/ObjectsTable/cells'

import {
  LinkedInUrlRenderer,
  MultiObjectRenderer,
  ObjectChipRenderer,
} from './StandardPropertiesRenderers'

const propertyDisplayOverrides = {
  [NativeObjectTypes.Person]: {
    email: {
      tableWidth: 160,
      propertyRenderer: ObjectChipRenderer,
    },
    linkedInUrl: {
      tableWidth: 48,
      propertyRenderer: LinkedInUrlRenderer,
    },
    firstName: {
      tableWidth: 80,
    },
    lastName: {
      tableWidth: 80,
    },
  },
  [NativeObjectTypes.Organization]: {
    domain: {
      tableWidth: 48,
      propertyRenderer: ObjectChipRenderer,
    },
    doesBusinessWith: {
      tableWidth: 156,
    },
    founded: {
      stringFormatter: (value: string) => {
        return value ? parseInt(value).toString() : null
      },
    },
  },
  [NativeObjectTypes.MeetingRecording]: {
    people: {
      tableWidth: 138,
      propertyRenderer: MultiObjectRenderer,
    },
    domains: {
      tableWidth: 138,
      propertyRenderer: MultiObjectRenderer,
    },
    statusLabel: {
      propertyRenderer: EnumCellRenderer,
      tableWidth: 112,
    },
    statusReason: {
      propertyRenderer: EnumCellRenderer,
      tableWidth: 212,
    },
    userWasInMeetings: {
      tableWidth: 72,
    },
  },
}

export default propertyDisplayOverrides
