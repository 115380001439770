import { useCallback, useEffect, useState } from 'react'

import {
  Chip,
  Tooltip,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  Button,
  IconButton,
} from '@mui/material'
import { IconLoader, IconStack2 } from '@tabler/icons-react'
import toast from 'react-hot-toast'
import type { CRMObject } from 'types/graphql'

import { navigate } from '@redwoodjs/router'
import { routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import { actionButtonStyle } from 'src/components/Sidebar/styles'
import { logger } from 'src/lib/logger'

import { CREATE_THREAD_FROM_BUTTON } from '../mutations'

const defaultSx = {}

const ThreadCreateButton = ({
  workspaceId,
  sourceObject,
  otherObjects,
  title = null,
  onThreadCreated,
  variant = 'button',
  disableElevation = false,
  sx = defaultSx,
}: {
  workspaceId: string
  sourceObject: CRMObject
  otherObjects: CRMObject[]
  title?: string
  onThreadCreated: () => void
  variant?: 'button' | 'icon'
  disableElevation?: boolean
  sx?: any
}) => {
  const [createThread, { loading }] = useMutation(CREATE_THREAD_FROM_BUTTON)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [question, setQuestion] = useState('')
  const [isCreatingThread, setIsCreatingThread] = useState(false)
  const [createdThreadId, setCreatedThreadId] = useState<string | null>(null)

  const handleOpenDialogAndCreateThread = useCallback(
    async (e) => {
      e.stopPropagation()
      setDialogOpen(true)
      setIsCreatingThread(true)

      const input = {
        workspaceId,
        sourceObject: {
          objectId: sourceObject.objectId,
          objectType: sourceObject.objectType,
          workspaceId,
        },
        otherObjects: otherObjects.map((object) => ({
          objectId: object.objectId,
          objectType: object.objectType,
          workspaceId,
        })),
        title: null,
      }

      createThread({
        variables: input,
        onError: () => {
          toast.error('Error creating thread')
          setIsCreatingThread(false)
          setDialogOpen(false)
        },
        onCompleted: (data) => {
          setCreatedThreadId(data.createThread.id)
          setIsCreatingThread(false)
        },
      })
    },
    [workspaceId, sourceObject, otherObjects, createThread]
  )

  const handleSubmitQuestion = useCallback(() => {
    if (createdThreadId) {
      const queryParams = question ? `?q=${encodeURIComponent(question)}` : ''
      navigate(routes.thread({ threadId: createdThreadId }) + queryParams)
      setDialogOpen(false)
      setQuestion('')
      onThreadCreated()
    } else {
      // If thread creation is still in progress, wait for it
      setIsCreatingThread(true)
      const checkInterval = setInterval(() => {
        if (createdThreadId) {
          clearInterval(checkInterval)
          const queryParams = question
            ? `?q=${encodeURIComponent(question)}`
            : ''
          navigate(routes.thread({ threadId: createdThreadId }) + queryParams)
          setDialogOpen(false)
          setQuestion('')
          onThreadCreated()
          setIsCreatingThread(false)
        }
      }, 100)
      // Cleanup interval after 5 seconds to prevent infinite checking
      setTimeout(() => {
        clearInterval(checkInterval)
        if (!createdThreadId) {
          toast.error('Thread creation is taking longer than expected')
          setIsCreatingThread(false)
        }
      }, 5000)
    }
  }, [createdThreadId, question, onThreadCreated])

  useEffect(() => {
    return () => {
      logger.dev('ThreadCreateButton component unmounted', {
        workspaceId,
        sourceObject,
        otherObjects,
        title,
      })
    }
  }, [])

  const handleDialogClose = () => {
    setDialogOpen(false)
    setQuestion('')
  }

  return (
    <>
      <Tooltip
        title={'Create a new thread'}
        arrow={true}
      >
        {variant === 'button' ? (
          <Chip
            label={loading ? 'Creating...' : 'Thread'}
            icon={
              loading ? (
                <Box
                  sx={{
                    '@keyframes spin': {
                      from: {
                        transform: 'rotate(0deg)',
                      },
                      to: {
                        transform: 'rotate(360deg)',
                      },
                    },
                    animation: 'spin 1s linear infinite',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <IconLoader size={12} />
                </Box>
              ) : (
                <IconStack2 size={12} />
              )
            }
            clickable={true}
            onClick={handleOpenDialogAndCreateThread}
            variant="outlined"
            size="small"
            sx={{ ...actionButtonStyle, ...sx }}
            className={disableElevation ? 'disable-elevation' : ''}
          />
        ) : (
          <IconButton
            onClick={handleOpenDialogAndCreateThread}
            sx={actionButtonStyle}
            className={disableElevation ? 'disable-elevation' : ''}
          >
            <IconStack2 size={12} />
          </IconButton>
        )}
      </Tooltip>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth={true}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle>What would you like to know?</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            fullWidth
            multiline
            rows={2}
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            placeholder="Ask Day.ai anything ..."
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault()
                handleSubmitQuestion()
              }
            }}
          />
        </DialogContent>
        <DialogActions sx={{ p: 3, pt: 0 }}>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button
            onClick={handleSubmitQuestion}
            variant="contained"
            color="primary"
            disabled={isCreatingThread}
            disableElevation={true}
          >
            {isCreatingThread ? 'Preparing thread...' : 'Ask Question'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ThreadCreateButton
