import { useCallback, useMemo, useEffect, useReducer, useRef } from 'react'

import { useGridApiContext } from '@mui/x-data-grid-premium'
import _, { debounce } from 'lodash'
import type { UpdateViewInput, CreateViewInput } from 'types/graphql'

import { useQuery, useMutation } from '@redwoodjs/web'

import { logger } from 'src/lib/logger'

import {
  CREATE_VIEW_MUTATION,
  DELETE_VIEW_MUTATION,
  SHARE_VIEW_MUTATION,
  UPDATE_VIEW_MUTATION,
  UPDATE_VIEW_PIN_MUTATION,
} from './mutations'
import { VIEWS_QUERY } from './queries'
import ViewsContext from './ViewsContext'

// Define action types as constants to avoid typos
const VIEW_ACTIONS = {
  SET_VIEWS: 'SET_VIEWS',
  ADD_VIEW: 'ADD_VIEW',
  SET_CURRENT_VIEW: 'SET_CURRENT_VIEW',
  REMOVE_VIEW: 'REMOVE_VIEW',
  UPDATE_VIEW: 'UPDATE_VIEW',
  TOGGLE_PIN: 'TOGGLE_PIN',
  SET_SAVING_STATUS: 'SET_SAVING_STATUS',
  APPLY_GRID_STATE: 'APPLY_GRID_STATE',
  OPTIMISTIC_UPDATE: 'OPTIMISTIC_UPDATE',
  REVERT_OPTIMISTIC: 'REVERT_OPTIMISTIC',
}

// Enhanced state structure
const initialState = {
  views: [],
  pinnedViews: [],
  currentView: null,
  saving: false,
  error: null,
  pendingOperations: {}, // Track operations that are in-flight
  lastSyncedGridState: null, // Last state synced with server
  optimisticUpdates: [], // For tracking and potentially rolling back optimistic updates
}

function viewsReducer(state, action) {
  switch (action.type) {
    case VIEW_ACTIONS.SET_VIEWS: {
      const pinnedViews = action.payload
        .filter((view) => typeof view.position === 'number')
        .sort((a, b) => a.position - b.position)

      return {
        ...state,
        views: action.payload,
        pinnedViews,
        currentView:
          state.currentView || (pinnedViews.length > 0 ? pinnedViews[0] : null),
        error: null,
      }
    }

    case VIEW_ACTIONS.ADD_VIEW: {
      const newView = action.payload
      const newViews = [...state.views, newView]
      const pinnedViews = newViews
        .filter((view) => typeof view.position === 'number')
        .sort((a, b) => a.position - b.position)

      return {
        ...state,
        views: newViews,
        pinnedViews,
        currentView: newView,
        error: null,
      }
    }

    case VIEW_ACTIONS.UPDATE_VIEW: {
      const { id, changes } = action.payload
      const updatedViews = state.views.map((view) =>
        view.id === id ? { ...view, ...changes } : view
      )

      const pinnedViews = updatedViews
        .filter((view) => typeof view.position === 'number')
        .sort((a, b) => a.position - b.position)

      const updatedCurrentView =
        state.currentView?.id === id
          ? { ...state.currentView, ...changes }
          : state.currentView

      return {
        ...state,
        views: updatedViews,
        pinnedViews,
        currentView: updatedCurrentView,
        lastSyncedGridState:
          changes.gridState && id === state.currentView?.id
            ? changes.gridState
            : state.lastSyncedGridState,
      }
    }

    case VIEW_ACTIONS.SET_CURRENT_VIEW: {
      const newCurrentView = action.payload

      return {
        ...state,
        currentView: newCurrentView,
        lastSyncedGridState: newCurrentView?.gridState || null,
      }
    }

    case VIEW_ACTIONS.REMOVE_VIEW: {
      const viewId = action.payload
      const updatedViews = state.views.filter((view) => view.id !== viewId)
      const pinnedViews = updatedViews
        .filter((view) => typeof view.position === 'number')
        .sort((a, b) => a.position - b.position)

      // Determine new current view if we're removing the current one
      const currentViewChanged = state.currentView?.id === viewId
      let newCurrentView = state.currentView

      if (currentViewChanged) {
        const currentIndex = state.pinnedViews.findIndex((v) => v.id === viewId)
        newCurrentView =
          pinnedViews[currentIndex] ||
          pinnedViews[currentIndex - 1] ||
          pinnedViews[0] ||
          null
      }

      return {
        ...state,
        views: updatedViews,
        pinnedViews,
        currentView: newCurrentView,
        lastSyncedGridState: newCurrentView?.gridState || null,
      }
    }

    case VIEW_ACTIONS.TOGGLE_PIN: {
      const { id, pinned, position } = action.payload

      const updatedViews = state.views.map((view) => {
        if (view.id !== id) return view

        return {
          ...view,
          position: pinned ? position : undefined,
        }
      })

      const pinnedViews = updatedViews
        .filter((view) => typeof view.position === 'number')
        .sort((a, b) => a.position - b.position)

      return {
        ...state,
        views: updatedViews,
        pinnedViews,
      }
    }

    case VIEW_ACTIONS.SET_SAVING_STATUS: {
      return {
        ...state,
        saving: action.payload,
      }
    }

    case VIEW_ACTIONS.APPLY_GRID_STATE: {
      const gridState = action.payload

      // Only update if we have a current view
      if (!state.currentView) return state

      const updatedCurrentView = {
        ...state.currentView,
        gridState,
      }

      // Update the view in the array as well
      const updatedViews = state.views.map((view) =>
        view.id === updatedCurrentView.id ? updatedCurrentView : view
      )

      return {
        ...state,
        views: updatedViews,
        currentView: updatedCurrentView,
        lastSyncedGridState: gridState,
      }
    }

    case VIEW_ACTIONS.OPTIMISTIC_UPDATE: {
      const { operationId, changes } = action.payload

      return {
        ...state,
        pendingOperations: {
          ...state.pendingOperations,
          [operationId]: {
            previousState: _.cloneDeep(state),
            changes,
          },
        },
      }
    }

    case VIEW_ACTIONS.REVERT_OPTIMISTIC: {
      const { operationId } = action.payload
      const pendingOp = state.pendingOperations[operationId]

      if (!pendingOp) return state

      // Remove this operation from pending list
      const { [operationId]: _, ...remainingOperations } =
        state.pendingOperations

      // Revert to previous state
      return {
        ...pendingOp.previousState,
        pendingOperations: remainingOperations,
        error: action.payload.error || 'Operation failed',
      }
    }

    default:
      return state
  }
}

const ViewsProvider = ({
  children,
  objectType,
  workspaceId,
  lastGridChangeAt,
  setInitialized,
  lastUserInteraction,
  onSave,
}) => {
  const [state, dispatch] = useReducer(viewsReducer, {
    ...initialState,
    objectType,
  })
  const {
    data: viewsData,
    loading,
    error,
    refetch,
  } = useQuery(VIEWS_QUERY, {
    variables: { objectType, workspaceId },
    skip: !objectType || !workspaceId,
  })

  const apiGridRef = useGridApiContext()
  const initializedFromDataRef = useRef(false)
  const optimisticUpdateCounterRef = useRef(0)

  // Grid state management
  const getExternalGridState = useCallback(() => {
    const gridState = apiGridRef.current?.exportState()
    // Remove preference panel from the state
    if (gridState && gridState.preferencePanel) {
      delete gridState.preferencePanel
    }
    return gridState
  }, [apiGridRef])

  const setExternalGridState = useCallback(
    (gridState) => {
      if (!gridState || !apiGridRef.current) return
      apiGridRef.current.restoreState(gridState)
    },
    [apiGridRef]
  )

  // Initialize with fetched data
  useEffect(() => {
    if (!initializedFromDataRef.current && viewsData?.views) {
      dispatch({
        type: VIEW_ACTIONS.SET_VIEWS,
        payload: viewsData.views,
      })

      initializedFromDataRef.current = true
      setInitialized()
    }
  }, [viewsData, setInitialized])

  // GraphQL mutations
  const [createViewMutation, { loading: createViewLoading }] =
    useMutation(CREATE_VIEW_MUTATION)
  const [updateViewMutation, { loading: updateViewLoading }] =
    useMutation(UPDATE_VIEW_MUTATION)
  const [deleteViewMutation, { loading: deleteViewLoading }] =
    useMutation(DELETE_VIEW_MUTATION)
  const [shareViewMutation, { loading: shareViewLoading }] =
    useMutation(SHARE_VIEW_MUTATION)
  const [updateViewPinMutation, { loading: updateViewPinLoading }] =
    useMutation(UPDATE_VIEW_PIN_MUTATION)

  // Track saving status
  useEffect(() => {
    const isSaving =
      updateViewLoading ||
      createViewLoading ||
      deleteViewLoading ||
      shareViewLoading ||
      updateViewPinLoading

    dispatch({
      type: VIEW_ACTIONS.SET_SAVING_STATUS,
      payload: isSaving,
    })
  }, [
    updateViewLoading,
    createViewLoading,
    deleteViewLoading,
    shareViewLoading,
    updateViewPinLoading,
  ])

  // Helper for generating operation IDs
  const getNextOperationId = useCallback(() => {
    optimisticUpdateCounterRef.current += 1
    return `op-${Date.now()}-${optimisticUpdateCounterRef.current}`
  }, [])

  // Create a new view
  const createView = useCallback(
    async (input: CreateViewInput) => {
      const operationId = getNextOperationId()
      try {
        // Prepare the view with current grid state
        const gridState = getExternalGridState()
        const newView = {
          ...input,
          id: `temp-${operationId}`,
          gridState,
          workspaceId,
          position: input.position ?? state.pinnedViews.length,
        }

        // Optimistically update state
        dispatch({
          type: VIEW_ACTIONS.OPTIMISTIC_UPDATE,
          payload: {
            operationId,
            changes: { newView },
          },
        })

        dispatch({
          type: VIEW_ACTIONS.ADD_VIEW,
          payload: newView,
        })

        // Execute mutation
        const response = await createViewMutation({
          variables: {
            input: {
              ...input,
              gridState,
              workspaceId,
              position: input.position ?? state.pinnedViews.length,
            },
          },
        })

        if (response.data?.createView) {
          // Replace temp ID with real one
          dispatch({
            type: VIEW_ACTIONS.UPDATE_VIEW,
            payload: {
              id: newView.id,
              changes: response.data.createView,
            },
          })

          onSave()
          return response.data.createView
        }

        return null
      } catch (error) {
        logger.error('Error creating view:', error)

        // Revert optimistic update
        dispatch({
          type: VIEW_ACTIONS.REVERT_OPTIMISTIC,
          payload: { operationId, error },
        })

        throw error
      }
    },
    [
      createViewMutation,
      workspaceId,
      getExternalGridState,
      state.pinnedViews.length,
      getNextOperationId,
      onSave,
    ]
  )

  // Update an existing view
  const updateView = useCallback(
    async ({ id, input }: { id: string; input: UpdateViewInput }) => {
      const operationId = getNextOperationId()

      try {
        // For optimistic updates, we need the updated grid state
        const gridState = input.gridState || getExternalGridState()
        input.workspaceId = workspaceId

        // Track previous state before optimistic update
        dispatch({
          type: VIEW_ACTIONS.OPTIMISTIC_UPDATE,
          payload: {
            operationId,
            changes: { updatedView: { id, ...input } },
          },
        })

        // Apply optimistic update
        dispatch({
          type: VIEW_ACTIONS.UPDATE_VIEW,
          payload: {
            id,
            changes: { ...input, gridState },
          },
        })

        // Execute actual mutation
        const response = await updateViewMutation({
          variables: {
            id,
            input: {
              ...input,
              gridState,
            },
          },
        })

        onSave()
        return response.data?.updateView
      } catch (error) {
        logger.error('Error updating view:', error)

        // Revert optimistic update
        dispatch({
          type: VIEW_ACTIONS.REVERT_OPTIMISTIC,
          payload: { operationId, error },
        })

        throw error
      }
    },
    [
      updateViewMutation,
      workspaceId,
      getExternalGridState,
      getNextOperationId,
      onSave,
    ]
  )

  // Delete a view
  const deleteView = useCallback(
    async (viewId) => {
      if (!viewId) return
      const operationId = getNextOperationId()

      try {
        // Track state before deletion for potential rollback
        dispatch({
          type: VIEW_ACTIONS.OPTIMISTIC_UPDATE,
          payload: {
            operationId,
            changes: { deletedViewId: viewId },
          },
        })

        // Optimistically remove view
        dispatch({
          type: VIEW_ACTIONS.REMOVE_VIEW,
          payload: viewId,
        })

        // Execute actual deletion
        await deleteViewMutation({
          variables: { id: viewId, workspaceId },
        })

        return true
      } catch (error) {
        logger.error('Error deleting view:', error)

        // Revert optimistic update
        dispatch({
          type: VIEW_ACTIONS.REVERT_OPTIMISTIC,
          payload: { operationId, error },
        })

        throw error
      }
    },
    [deleteViewMutation, workspaceId, getNextOperationId]
  )

  // Handle pin/unpin
  const togglePin = useCallback(
    async (id, shouldPin) => {
      const operationId = getNextOperationId()

      try {
        const position = shouldPin ? state.pinnedViews.length : undefined

        // Track state before change
        dispatch({
          type: VIEW_ACTIONS.OPTIMISTIC_UPDATE,
          payload: {
            operationId,
            changes: {
              viewId: id,
              pinChange: {
                pinned: shouldPin,
                position,
              },
            },
          },
        })

        // Optimistically update pin status
        dispatch({
          type: VIEW_ACTIONS.TOGGLE_PIN,
          payload: {
            id,
            pinned: shouldPin,
            position,
          },
        })

        // If pinning, also make it the current view
        if (shouldPin) {
          const viewToPin = state.views.find((v) => v.id === id)
          if (viewToPin) {
            dispatch({
              type: VIEW_ACTIONS.SET_CURRENT_VIEW,
              payload: { ...viewToPin, position },
            })
          }
        }

        // Execute actual mutation
        const response = await updateViewPinMutation({
          variables: {
            id,
            workspaceId,
            position,
            objectType,
          },
        })

        return response.data?.updateViewPin
      } catch (error) {
        logger.error('Error toggling pin:', error)

        // Revert optimistic update
        dispatch({
          type: VIEW_ACTIONS.REVERT_OPTIMISTIC,
          payload: { operationId, error },
        })

        throw error
      }
    },
    [
      updateViewPinMutation,
      state.views,
      state.pinnedViews,
      objectType,
      workspaceId,
      getNextOperationId,
    ]
  )

  // Convenience wrappers
  const addPin = useCallback((id) => togglePin(id, true), [togglePin])

  const removePin = useCallback((id) => togglePin(id, false), [togglePin])

  // Share a view with another user
  const shareView = useCallback(
    async (id, userId) => {
      try {
        const response = await shareViewMutation({
          variables: { id, userId },
        })
        return response.data?.shareView
      } catch (error) {
        logger.error('Error sharing view:', error)
        throw error
      }
    },
    [shareViewMutation]
  )

  // Set current view and restore its grid state
  const activateView = useCallback(
    (view) => {
      if (!view) return

      dispatch({
        type: VIEW_ACTIONS.SET_CURRENT_VIEW,
        payload: view,
      })

      if (view.gridState) {
        setExternalGridState(view.gridState)
      }
    },
    [setExternalGridState]
  )

  // Debounced handler for auto-saving grid state changes
  const debouncedHandleExternalGridChange = useMemo(
    () =>
      debounce(async () => {
        if (lastUserInteraction === 0 || !state.currentView?.id) {
          return
        }

        const currentGridState = getExternalGridState()

        // Skip if no changes detected
        if (_.isEqual(state.lastSyncedGridState, currentGridState)) {
          return
        }

        try {
          await updateView({
            id: state.currentView.id,
            input: {
              gridState: currentGridState,
              title: state.currentView.title,
              description: state.currentView.description,
              shareWithWorkspace: state.currentView.shareWithWorkspace,
            },
          })
        } catch (error) {
          logger.error('Error auto-saving grid state:', error)
        }
      }, 500),
    [
      updateView,
      getExternalGridState,
      lastUserInteraction,
      state.currentView,
      state.lastSyncedGridState,
    ]
  )

  // Trigger auto-save when grid state changes
  useEffect(() => {
    if (state.currentView?.id) {
      debouncedHandleExternalGridChange()
    }

    return () => {
      debouncedHandleExternalGridChange.cancel()
    }
  }, [
    lastGridChangeAt,
    debouncedHandleExternalGridChange,
    state.currentView?.id,
  ])

  // Context value with actions and state
  const contextValue = useMemo(() => {
    return {
      // State
      views: state.views,
      pinnedViews: state.pinnedViews,
      currentView: state.currentView,
      loading,
      saving: state.saving,
      error: error || state.error,
      objectType: state.objectType,
      // Actions
      createView,
      updateView,
      deleteView,
      shareView,
      addPin,
      removePin,
      activateView,
      refetchViews: refetch,

      // Direct dispatch for advanced usage
      dispatch,
    }
  }, [
    state,
    loading,
    error,
    createView,
    updateView,
    deleteView,
    shareView,
    addPin,
    removePin,
    activateView,
    refetch,
  ])

  return (
    <ViewsContext.Provider value={contextValue}>
      {children}
    </ViewsContext.Provider>
  )
}

export default ViewsProvider
