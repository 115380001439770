import { useCallback, useRef, useState, useEffect } from 'react'

import {
  Checkbox,
  IconButton,
  Menu,
  TextField,
  ListItem,
  FormControlLabel,
  Typography,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
  Box,
} from '@mui/material'
import {
  IconChevronDown,
  IconChevronLeft,
  IconPencil,
  IconProgressCheck,
} from '@tabler/icons-react'

import { modernButtonContainer } from 'src/lib/style'

import Row from '../Row/Row'

import useViews from './useViews'
import ViewDelete from './ViewDelete'

const ViewEdit = () => {
  const { currentView, updateView, saving } = useViews()

  const [createMenuEl, setCreateMenuEl] = useState<null | HTMLElement>(null)
  const [title, setViewTitle] = useState(currentView?.title || '')
  const [description, setDescription] = useState(currentView?.description || '')
  const [showDescription, setShowDescription] = useState(true)
  const [shareWithWorkspace, setShareWithWorkspace] = useState(
    currentView?.shareWithWorkspace || false
  )

  // Update local state when currentView changes
  useEffect(() => {
    if (currentView) {
      setViewTitle(currentView.title || '')
      setDescription(currentView.description || '')
      setShareWithWorkspace(currentView.shareWithWorkspace || false)
    }
  }, [currentView])

  const handleUpdate = useCallback(async () => {
    if (!currentView?.id || !title.trim()) return

    try {
      await updateView({
        id: currentView.id,
        input: {
          title: title.trim(),
          description: description.trim(),
          shareWithWorkspace,
        },
      })
      setCreateMenuEl(null)
    } catch (error) {
      console.error('Error updating view:', error)
    }
  }, [updateView, currentView, title, description, shareWithWorkspace])

  const titleRef = useRef<HTMLInputElement>(null)

  const handleEdit = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setCreateMenuEl(event.currentTarget)
      setTimeout(() => {
        titleRef.current?.focus()
      }, 100)
    },
    []
  )

  const handleTitleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setViewTitle(e.target.value)
    },
    []
  )

  const handleTitleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      e.stopPropagation()
      if (e.key === 'Enter') {
        handleUpdate()
      }
    },
    [handleUpdate]
  )

  const handleDescriptionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation()
      setDescription(e.target.value)
    },
    []
  )

  const handleDescriptionKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      e.stopPropagation()
    },
    []
  )

  const handleDescriptionToggle = useCallback(() => {
    setShowDescription((prev) => !prev)
  }, [])

  const handleWorkspaceShareChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setShareWithWorkspace(e.target.checked)
    },
    []
  )

  // Only render if we have a current view
  if (!currentView) return null

  return (
    <Box sx={modernButtonContainer}>
      <Button
        className="right flat"
        onClick={handleEdit}
        startIcon={<IconPencil size={12} />}
        size="small"
        disabled={saving}
      >
        Edit view
      </Button>
      <Menu
        anchorEl={createMenuEl}
        open={!!createMenuEl}
        onClose={() => setCreateMenuEl(null)}
        sx={{ '& .MuiList-root': { pb: 0 } }}
      >
        <Row sx={{ px: 2, py: 1 }}>
          <Typography
            sx={{ fontSize: '14px', fontWeight: 600, letterSpacing: '-0.4px' }}
          >
            Edit view
          </Typography>
        </Row>
        <ListItem>
          <Row
            sx={{ width: '320px' }}
            gap={1}
          >
            <TextField
              inputRef={titleRef}
              label="Title"
              value={title}
              onChange={handleTitleChange}
              onKeyDown={handleTitleKeyDown}
              fullWidth={true}
              size="small"
            />
            <IconButton
              onClick={handleDescriptionToggle}
              sx={{
                p: '4px',
                borderRadius: '3px',
              }}
            >
              {!showDescription ? (
                <IconChevronLeft size={20} />
              ) : (
                <IconChevronDown size={20} />
              )}
            </IconButton>
          </Row>
        </ListItem>

        {showDescription && (
          <ListItem>
            <TextField
              label="Description"
              value={description}
              onChange={handleDescriptionChange}
              onKeyDown={handleDescriptionKeyDown}
              fullWidth={true}
              size="small"
              multiline={true}
              rows={3}
              autoComplete="off"
            />
          </ListItem>
        )}
        {showDescription && (
          <ListItem>
            <FormControlLabel
              control={
                <Checkbox
                  checked={shareWithWorkspace}
                  onChange={handleWorkspaceShareChange}
                  size="small"
                  color="secondary"
                  sx={{
                    opacity: 0.6,
                    '&.Mui-checked': { opacity: 1 },
                  }}
                />
              }
              label="Share with workspace"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: '12px',
                  letterSpacing: '-0.22px',
                },
              }}
            />
          </ListItem>
        )}

        <ViewDelete />
        <ListItemButton
          onClick={handleUpdate}
          disabled={!title.trim() || saving}
          sx={{
            background: (theme) => theme.palette.secondary.main,
            '& .MuiTypography-root, & svg': {
              color: (theme) => theme.palette.secondary.contrastText,
            },
            '&:hover': {
              background: (theme) => theme.palette.secondary.dark,
            },
            '& .MuiListItemIcon-root': {
              ml: '-2px',
            },
            '&.Mui-disabled': {
              opacity: 0.5,
              background: (theme) => theme.palette.secondary.main,
            },
          }}
        >
          <ListItemIcon sx={{ minWidth: '30px' }}>
            <IconProgressCheck size={20} />
          </ListItemIcon>
          <ListItemText
            primary={saving ? 'Updating...' : 'Update'}
            primaryTypographyProps={{
              fontSize: '12px',
              letterSpacing: '-0.22px',
              fontWeight: 600,
            }}
          />
        </ListItemButton>
      </Menu>
    </Box>
  )
}

export default ViewEdit
