import { useCallback, useContext } from 'react'

import {
  Button,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  GridPreferencePanelsValue,
  useGridApiContext,
} from '@mui/x-data-grid-premium'
import { IconAdjustmentsHorizontal, IconPlus } from '@tabler/icons-react'

import { useAuth } from 'src/auth'
import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes } from 'src/lib/objects'
import { modernButtonContainer, modernTabStyles } from 'src/lib/style'

import Row from '../Row/Row'

import { viewManagerContainerSx, tabsContainerSx } from './styles'
import ViewCreate from './ViewCreate'
import ViewEdit from './ViewEdit'
import ViewFilters from './ViewFilters'
import ViewRemovePin from './ViewRemovePin'
import ViewsContext from './ViewsContext'
import type { ViewsContextValue } from './ViewsContext'

interface ViewManagerProps {
  setPanelAnchorEl: (element: HTMLElement | null) => void
  workspaceId: string
}

const ViewManager: React.FC<ViewManagerProps> = ({
  setPanelAnchorEl,
  workspaceId,
}) => {
  const { currentUser } = useAuth()
  const { setSidebarObject } = useContext(DayContext)
  const viewsContext = useContext(ViewsContext) as ViewsContextValue

  const {
    views,
    pinnedViews,
    currentView,
    loading,
    saving: _saving,
    activateView,
    objectType,
  } = viewsContext

  const apiRef = useGridApiContext()

  const handleCreateColumn = useCallback(() => {
    setSidebarObject({
      objectType: NativeObjectTypes.PropertyDefinition,
      mode: 'create',
      objectId: 'new',
      workspaceId,
      properties: {
        name: '',
        description: '',
        objectTypeId: objectType || '',
        aiManaged: true,
        useWeb: false,
        options: [],
      },
    })
  }, [setSidebarObject, workspaceId, objectType])

  const handleViewChange = useCallback(
    (viewId: string | null) => {
      if (!viewId) return

      const view = views.find((v) => v.id === viewId)
      if (view) {
        activateView(view)
      }
    },
    [views, activateView]
  )

  return (
    <>
      <Row sx={viewManagerContainerSx}>
        <Row className="view-manager-tabs-container">
          <Row sx={{ ...tabsContainerSx, ...modernTabStyles }}>
            {pinnedViews.length > 0 ? (
              <Tabs
                value={currentView?.id || pinnedViews[0]?.id || ''}
                onChange={(_, viewId) => {
                  if (pinnedViews.some((v) => v.id === viewId)) {
                    handleViewChange(viewId)
                  }
                }}
                visibleScrollbar={true}
              >
                {pinnedViews.map((view) => (
                  <Tab
                    key={view.id}
                    label={
                      <Row sx={{ justifyContent: 'space-between' }}>
                        <Typography>{view.title}</Typography>
                        {currentView?.id === view.id &&
                          currentUser?.id === view.creatorId && (
                            <Row sx={{ ml: '6px' }}>
                              <ViewRemovePin />
                            </Row>
                          )}
                      </Row>
                    }
                    value={view.id}
                    disableFocusRipple={true}
                    disabled={loading}
                  />
                ))}
              </Tabs>
            ) : (
              <Tabs value={loading ? 'loading' : 'all'}>
                <Tab
                  value={loading ? 'loading' : 'all'}
                  disabled={loading}
                  label={
                    <Row>
                      <Typography>{loading ? 'Loading...' : 'All'}</Typography>
                    </Row>
                  }
                />
              </Tabs>
            )}
            <Row sx={{ ...modernButtonContainer }}>
              <ViewCreate />

              <ViewFilters />
            </Row>
          </Row>
          <Row gap={0}>
            <Row sx={{ ...modernButtonContainer }}>
              <Button
                className="right"
                variant="outlined"
                size="small"
                startIcon={
                  <IconAdjustmentsHorizontal
                    size={12}
                    stroke={2.5}
                  />
                }
                onClick={(e) => {
                  e.stopPropagation()
                  setPanelAnchorEl(e.currentTarget)
                  apiRef?.current?.showPreferences(
                    GridPreferencePanelsValue.columns
                  )
                }}
              >
                Display
              </Button>
              <Tooltip title="Add Column (Custom Property)">
                <IconButton
                  className="right flat"
                  onClick={handleCreateColumn}
                >
                  <IconPlus
                    size={12}
                    stroke={2.5}
                  />
                </IconButton>
              </Tooltip>
            </Row>

            {(currentView?.id || true) && <ViewEdit />}
          </Row>
        </Row>
      </Row>
    </>
  )
}

export default ViewManager
