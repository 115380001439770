import { useState } from 'react'

import {
  Button,
  ListItemButton,
  ListItemText,
  Menu,
  Tooltip,
} from '@mui/material'
import { useGridApiContext } from '@mui/x-data-grid-premium'
import { IconFilterFilled } from '@tabler/icons-react'

import { logger } from 'src/lib/logger'

const ViewFilters = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const apiGridRef = useGridApiContext()
  const columns = apiGridRef.current.getAllColumns()

  logger.dev({ columns })
  return (
    <>
      <Tooltip
        title="Filter what data is shown"
        arrow={true}
      >
        <Button
          className="flat left"
          variant="outlined"
          size="small"
          startIcon={
            <IconFilterFilled
              size={12}
              stroke={20}
            />
          }
          onClick={(e) => {
            e.stopPropagation()
            setAnchorEl(e.currentTarget)
          }}
        >
          Filter
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        onClose={(_) => setAnchorEl(null)}
        open={!!anchorEl}
      >
        {columns.map((col, index) => (
          <ListItemButton key={`${index}-column-${col?.id}`}>
            <ListItemText primary={col.headerName} />
          </ListItemButton>
        ))}
      </Menu>
    </>
  )
}

export default ViewFilters
