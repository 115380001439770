import { Chip, Tooltip } from '@mui/material'
import {
  IconCancel,
  IconCheck,
  IconCircleFilled,
  IconClock,
  IconLoader,
  IconPhone,
  IconThumbUp,
  IconX,
} from '@tabler/icons-react'
import type { ObjectPropertyDefinitionOption } from 'types/graphql'

import MultiObjectChip from 'src/components/Chips/MultiObjectChip/MultiObjectChip'
import { OrganizationIndustryTypes } from 'src/components/Organizations/organizations'
import { logger } from 'src/lib/logger'
import { statusMetadata as MeetingRecordingStatusMetadata } from 'src/lib/meetingRecordingFormatting'
import { NativeObjectTypes, type NativeObjectType } from 'src/lib/objects'

import Row from '../../Row/Row'

const rowSx = {
  width: '100%',
  height: '100%',
}

export const PeopleCellRenderer = ({ workspaceId, propertyId }) => {
  return (params) => {
    const { row } = params
    const value = row?.object?.properties?.standard?.[propertyId]

    if (!value?.value) {
      logger.warn('No value for PeopleCellRenderer to render')
      return null
    }

    return (
      <Row sx={rowSx}>
        <MultiObjectChip
          workspaceId={workspaceId}
          crmObjects={value.value}
        />
      </Row>
    )
  }
}

export const OrganizationsCellRenderer = ({ workspaceId, propertyId }) => {
  return (params) => {
    const { row } = params
    const value = row?.object?.properties?.standard?.[propertyId]

    if (!value?.value) {
      logger.warn('No value for OrganizationsCellRenderer to render')
      return null
    }

    return (
      <Row sx={rowSx}>
        <MultiObjectChip
          workspaceId={workspaceId}
          crmObjects={value.value}
        />
      </Row>
    )
  }
}

interface EnumPropertyMetadataOption {
  color: string
  label: string
  icon?: React.ElementType
}

interface EnumPropertyMetadata {
  tooltipTitle: (row: any) => string
  options: Record<string, EnumPropertyMetadataOption>
}

export const enumPropertyMetadata: Record<
  NativeObjectType,
  Record<string, EnumPropertyMetadata>
> = {
  [NativeObjectTypes.Organization]: {
    doesBusinessWith: {
      tooltipTitle: (_row) => null,
      options: {
        B2B: {
          color: 'info',
          label: 'B2B',
          icon: IconCheck,
        },
        B2C: {
          color: 'info',
          label: 'B2C',
          icon: IconCheck,
        },
        B2G: {
          color: 'info',
          label: 'B2G',
          icon: IconCheck,
        },
      },
    },
    industry: {
      tooltipTitle: (_row) => null,
      options: OrganizationIndustryTypes,
    },
  },
  [NativeObjectTypes.MeetingRecording]: {
    statusLabel: {
      tooltipTitle: (row) => {
        const statusSummary =
          MeetingRecordingStatusMetadata[
            row?.object?.properties?.standard?.statusLabel?.value
          ]?.explanation || ''
        const messageKey =
          row?.object?.properties?.standard?.statusReason?.value
        return `${statusSummary} ${row?.object?.properties?.standard?.statusMessage?.value || ''} ${messageKey ? 'CODE: ' + messageKey : ''}`
      },
      options: {
        ['JOINING_CALL']: {
          color: 'success',
          label: 'Joining Call',
          icon: IconPhone,
        },
        ['READY']: {
          color: 'success',
          label: 'Ready',
          icon: IconCheck,
        },
        ['IN_WAITING_ROOM']: {
          color: 'warning',
          label: 'In Waiting Room',
          icon: IconClock,
        },
        ['IN_CALL_NOT_RECORDING']: {
          color: 'warning',
          label: 'In Call Not Recording',
          icon: IconClock,
        },
        ['RECORDING_PERMISSION_ALLOWED']: {
          color: 'success',
          label: 'Recording Allowed',
          icon: IconThumbUp,
        },
        ['RECORDING_PERMISSION_DENIED']: {
          color: 'error',
          label: 'Recording Denied',
          icon: IconX,
        },
        ['IN_CALL_RECORDING']: {
          color: 'error',
          label: 'Recording',
          icon: IconCircleFilled,
        },
        ['PREPARING_RECORDING']: {
          color: 'info',
          label: 'Preparing Recording',
          icon: IconLoader,
        },
        ['CALL_ENDED_WITHOUT_RECORDING']: {
          color: 'info',
          label: 'No Recording',
          icon: IconCancel,
        },
        ['RECORDING_ERRORED']: {
          color: 'error',
          label: 'Errored',
          icon: IconX,
        },
      },
    },
    statusReason: {
      tooltipTitle: (row) => {
        return (
          row?.object?.properties?.standardProperties?.statusMessage?.value ||
          ''
        )
      },
      options: {
        ['CALL_ENDED_BY_PLATFORM_WAITING_ROOM_TIMEOUT']: {
          color: 'warning',
          label: 'Waiting Room Timeout',
          icon: IconClock,
        },
        ['ZOOM_SDK_APP_NOT_PUBLISHED']: {
          color: 'error',
          label: 'Zoom App Not Published',
          icon: IconX,
        },
        ['ZOOM_LOCAL_RECORDING_REQUEST_DENIED_BY_HOST']: {
          color: 'error',
          label: 'Recording Denied by Host',
          icon: IconX,
        },
        ['TIMEOUT_EXCEEDED_EVERYONE_LEFT']: {
          color: 'info',
          label: 'Everyone Left Call',
          icon: IconCancel,
        },
        ['ZOOM_LOCAL_RECORDING_GRANT_NOT_SUPPORTED']: {
          color: 'error',
          label: 'Recording Not Supported',
          icon: IconX,
        },
        ['TIMEOUT_EXCEEDED_RECORDING_PERMISSION_DENIED']: {
          color: 'error',
          label: 'Recording Permission Timeout',
          icon: IconClock,
        },
        ['TIMEOUT_EXCEEDED_IN_CALL_NOT_RECORDING']: {
          color: 'warning',
          label: 'Recording Start Timeout',
          icon: IconClock,
        },
        ['TIMEOUT_EXCEEDED_WAITING_ROOM']: {
          color: 'warning',
          label: 'Waiting Room Timeout',
          icon: IconClock,
        },
        ['ZOOM_LOCAL_RECORDING_DISABLED']: {
          color: 'error',
          label: 'Recording Disabled',
          icon: IconX,
        },
        ['ZOOM_LOCAL_RECORDING_REQUEST_DISABLED']: {
          color: 'error',
          label: 'Recording Requests Disabled',
          icon: IconX,
        },
        ['ZOOM_HOST_NOT_PRESENT']: {
          color: 'warning',
          label: 'Host Not Present',
          icon: IconClock,
        },
        ['TIMEOUT_EXCEEDED_ONLY_BOTS_DETECTED_USING_PARTICIPANT_EVENTS']: {
          color: 'info',
          label: 'Only Bots Detected',
          icon: IconCancel,
        },
        ['BOT_KICKED_FROM_WAITING_ROOM']: {
          color: 'error',
          label: 'Kicked from Waiting Room',
          icon: IconX,
        },
        ['BOT_RECEIVED_LEAVE_CALL']: {
          color: 'info',
          label: 'Bot Left Call',
          icon: IconCancel,
        },
        ['MEETING_NOT_FOUND']: {
          color: 'error',
          label: 'Meeting Not Found',
          icon: IconX,
        },
      },
    },
  },
  [NativeObjectTypes.Contact]: {},
  [NativeObjectTypes.Opportunity]: {},
  [NativeObjectTypes.Pipeline]: {},
  [NativeObjectTypes.Stage]: {},
  [NativeObjectTypes.Action]: {},
  [NativeObjectTypes.Page]: {},
  [NativeObjectTypes.WebPage]: {},
  [NativeObjectTypes.MeetingRecordingClip]: {},
  [NativeObjectTypes.Event]: {},
  [NativeObjectTypes.Workspace]: {},
  [NativeObjectTypes.GmailThread]: {},
  [NativeObjectTypes.SlackChannel]: {},
  [NativeObjectTypes.View]: {},
  [NativeObjectTypes.UserContext]: {},
  [NativeObjectTypes.PropertyDefinition]: {},
}

export const enumOptionsAsPropDefOptions = (objectType, propertyId) => {
  const metadata = enumPropertyMetadata?.[objectType]?.[
    propertyId
  ] as EnumPropertyMetadata
  if (!metadata) {
    return null
  }
  return Object.entries(metadata?.options).map(([key, value]) => ({
    id: key,
    name: value.label,
    description: '',
    propertyDefinitionId: key,
  })) as ObjectPropertyDefinitionOption[]
}

export const EnumCellRenderer = ({ propertyId, objectType }) => {
  return (params) => {
    const { row } = params
    const value = row?.object?.properties?.standard?.[propertyId]

    if (!value?.value) return null

    const metadata = enumPropertyMetadata?.[objectType]?.[propertyId]

    const chipMetadata = metadata?.options?.[value?.value]
    const tooltipTitle = metadata?.tooltipTitle?.(row)
    const color = chipMetadata?.color
    const label = chipMetadata?.label

    if (!metadata) {
      logger.warn(
        `EnumCellRenderer: No metadata found for propertyId: ${propertyId} and value: ${value}`,
        {
          objectType,
          propertyId,
          value,
        }
      )
      return null
    }

    const chipSx = {
      border: 'none',
      '& .MuiChip-icon': {
        color: (theme) => theme.palette?.[color]?.main,
        ml: '-4px',
        flexShrink: 0,
        width: '8px',
        height: '8px',
      },
      '& .MuiChip-label': {
        flexShrink: 1,
        pl: '8px',
      },
    }

    return (
      <Tooltip
        title={tooltipTitle}
        arrow={true}
      >
        <Chip
          size="small"
          icon={<IconCircleFilled size={8} />}
          label={label}
          clickable={false}
          sx={chipSx}
          variant="outlined"
        />
      </Tooltip>
    )
  }
}
