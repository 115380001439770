import { useMemo, useReducer, useRef, type Dispatch } from 'react'
import { useCallback, useEffect } from 'react'

import { useLazyQuery } from '@apollo/client'
import type { DayObjectSearchEntry } from 'types/graphql'
import { isUUID } from 'validator'

import { useAuth } from 'src/auth'
import { logger as loggerDev } from 'src/lib/logger'
import {
  NativeObjectTypes,
  ObjectTypeMetadata,
  type NativeObjectType,
} from 'src/lib/objects'

import ObjectsContext from './ObjectsContext'
import type { SyncState } from './ObjectsContext'
import {
  GET_WORKSPACE_OBJECTS_BY_IDS,
  GET_WORKSPACE_OBJECTS_IDS,
  GET_WORKSPACE_OBJECTS_UPDATED_SINCE,
} from './queries'
import {
  objectSyncReducer,
  type ObjectSyncReducerAction,
  type ObjectSyncReducerState,
} from './reducer'
import {
  upsertDayObjects,
  getDayObjectStateByType,
  buildFuseIndex,
  clearDayObjects,
  getDayObjectState,
  getSyncQueueEntries,
  addToSyncQueue,
  getNeverSyncedObjects,
  getDayObjects,
  getDayObjectSearchEntry,
} from './searchIndex'

const loggingEnabled = true

const logger = loggingEnabled
  ? loggerDev
  : {
      dev: () => {},
      info: () => {},
      warn: () => {},
      error: () => {},
    }

const ObjectsProvider = ({
  workspaceId,
  children,
}: {
  workspaceId: string
  children: React.ReactNode
}) => {
  const { currentUser } = useAuth()
  const initializedRef = useRef(false)
  const idsCompleteRef = useRef(new Set<NativeObjectType>())
  const typesCompleteRef = useRef(new Set<NativeObjectType>())
  const verificationOffsetsRef = useRef<Map<NativeObjectType, number>>(
    new Map()
  )

  const [state, dispatch]: [
    ObjectSyncReducerState,
    Dispatch<ObjectSyncReducerAction>,
  ] = useReducer(objectSyncReducer, null)

  const [getWorkspaceObjectsIds, { loading: idsLoading }] = useLazyQuery(
    GET_WORKSPACE_OBJECTS_IDS
  )

  const [getWorkspaceObjectsByIds, { loading: objectsLoading }] = useLazyQuery(
    GET_WORKSPACE_OBJECTS_BY_IDS
  )

  const [getObjectsUpdatedSince, { loading: objectsUpdatesLoading }] =
    useLazyQuery(GET_WORKSPACE_OBJECTS_UPDATED_SINCE)

  const handleResyncObject = useCallback(
    async (objectId: string, objectType: NativeObjectType) => {
      loggerDev.dev('handleResyncObject', { objectId, objectType })
      const result = await getWorkspaceObjectsByIds({
        variables: {
          workspaceId,
          objectType,
          objectIds: [objectId],
        },
      })

      const objects = result?.data?.workspaceObjectsByIds || []

      loggerDev.dev('handleResyncObject: Got results', {
        objectType,
        objectId,
        objects,
      })

      await upsertDayObjects({
        objects,
        objectType,
        workspaceId,
        synced: true,
      })
    },
    [workspaceId, getWorkspaceObjectsByIds]
  )

  const handleGetObjectIds = useCallback(
    async (objectType: NativeObjectType, offset = 0) => {
      logger.dev('handleGetObjectIds: Starting', { objectType, offset })

      const limit = 1000
      const variables = {
        workspaceId,
        objectType,
        offset,
        limit,
      }

      let result
      try {
        result = await getWorkspaceObjectsIds({
          variables,
        })
      } catch (error) {
        logger.error('Failed to get workspace object IDs', { error, variables })
        return // Don't update verification state if request failed
      }

      const objects = result?.data?.workspaceObjectsIds || []
      const objectIds = objects.map((obj) => obj.objectId)

      logger.dev('handleGetObjectIds: Got results', {
        objectType,
        offset,
        receivedCount: objectIds.length,
        firstId: objectIds[0],
        lastId: objectIds[objectIds.length - 1],
      })

      if (objectIds.length > 0) {
        let existingEntries
        try {
          existingEntries = await getDayObjects({
            workspaceId,
            objectType,
            objectIds,
          })
        } catch (error) {
          logger.error('Failed to get existing day objects', {
            error,
            objectIds,
          })
          return // Don't proceed if we can't verify existing objects
        }

        const existingIds = new Set(
          existingEntries.map((entry) => entry.objectId)
        )
        const missingIds = objectIds.filter((id) => !existingIds.has(id))

        if (missingIds.length > 0) {
          try {
            await addToSyncQueue({
              objectType,
              objectIds: missingIds,
              workspaceId,
            })
          } catch (error) {
            logger.error('Failed to add missing IDs to sync queue', {
              error,
              missingIds,
            })
            return // Don't update verification state if we couldn't queue missing IDs
          }
        }

        // Only update offset if everything succeeded
        verificationOffsetsRef.current.set(
          objectType,
          offset + objectIds.length
        )

        if (objectIds.length < limit) {
          idsCompleteRef.current.add(objectType)
        }

        const finalCurrentState = await getDayObjectStateByType({
          workspaceId,
          objectType,
        })
        dispatch({
          type: 'UPDATE_SYNC_STATUS_BY_TYPE',
          payload: {
            [objectType]: {
              ...finalCurrentState[objectType],
              needsMoreIds: objectIds.length === limit,
            },
          },
        })
      } else {
        idsCompleteRef.current.add(objectType)
      }
    },
    [workspaceId, getWorkspaceObjectsIds]
  )

  const getObjectsLastUpdatedAt = useCallback(async () => {
    //return true
    if (!process.env.HOST.includes('localhost')) {
      return
    }

    const currentState = await getDayObjectState({
      workspaceId,
    })

    let resultCount = 0

    const mostRecentSyncedAtByObjectType = Object.fromEntries(
      Object.entries(currentState).map(([objectType, state]) => [
        objectType,
        state.updatedAt,
      ])
    )

    const neverSyncedObjects = await getNeverSyncedObjects({
      workspaceId,
    })
    const neverSyncedObjectsByType = neverSyncedObjects.reduce(
      (acc, object) => {
        acc[object.objectType] = [...(acc[object.objectType] || []), object]
        return acc
      },
      {} as Record<NativeObjectType, DayObjectSearchEntry[]>
    )
    for (const objectType of Object.keys(neverSyncedObjectsByType)) {
      if (neverSyncedObjectsByType[objectType].length > 0) {
        resultCount += neverSyncedObjectsByType[objectType].length

        logger.dev(
          'getObjectsLastUpdatedAt: NEVER SYNCED Adding to sync queue',
          {
            objectType,
            objectIds: neverSyncedObjectsByType?.[objectType]?.length,
          }
        )

        await addToSyncQueue({
          objectType: objectType as NativeObjectType,
          objectIds: neverSyncedObjectsByType[objectType].map(
            (object) => object.objectId
          ),
          workspaceId,
        })
      }
    }

    for (const objectType of Object.keys(currentState)) {
      if (!objectType || !mostRecentSyncedAtByObjectType[objectType]) continue

      logger.dev('getObjectsLastUpdatedAt: Checking object type', {
        objectType,
        mostRecentSyncedAt: mostRecentSyncedAtByObjectType[objectType],
      })

      const updatedSinceResults = await getObjectsUpdatedSince({
        variables: {
          workspaceId,
          objectType: objectType as NativeObjectType,
          updatedSince: mostRecentSyncedAtByObjectType[objectType],
        },
      })

      const updatedSinceObjects =
        updatedSinceResults?.data?.workspaceObjectsUpdatedSince || []
      if (updatedSinceObjects.length > 0) {
        resultCount += updatedSinceObjects.length
        logger.dev(
          'getObjectsLastUpdatedAt: UPDATED SINCE Adding to sync queue',
          {
            objectType,
            objectIds: updatedSinceObjects.length,
          }
        )
        for (const object of updatedSinceObjects) {
          await addToSyncQueue({
            objectType: object.objectType as NativeObjectType,
            objectIds: [object.objectId],
            workspaceId,
          })
        }
      }
    }
    return resultCount
  }, [workspaceId, getObjectsUpdatedSince])

  const handleGetWorkspaceObjectsByIds = useCallback(
    async (objectType: NativeObjectType, objectIds: string[]) => {
      if (!initializedRef.current || objectsLoading) {
        return
      }

      if (!workspaceId) {
        logger.warn('No workspaceId to get workspace objects by ids')
        return
      }

      if (objectIds.length === 0) {
        return
      }

      const result = await getWorkspaceObjectsByIds({
        variables: {
          workspaceId,
          objectType,
          objectIds,
        },
      })

      // Add type validation before upserting
      const objects = result?.data?.workspaceObjectsByIds || []
      const validObjects = objects.filter(
        (obj) => obj.objectType === objectType
      )

      await upsertDayObjects({
        objects: validObjects, // Only upsert objects with matching type
        objectType,
        workspaceId,
        synced: true,
      })

      const finalCurrentState = await getDayObjectStateByType({
        workspaceId,
        objectType,
      })
      dispatch({
        type: 'UPDATE_SYNC_STATUS_BY_TYPE',
        payload: {
          [objectType]: finalCurrentState[objectType],
        },
      })

      // After successfully upserting objects, rebuild the Fuse index
      try {
        await buildFuseIndex(workspaceId)
      } catch (error) {
        logger.error('Failed to rebuild Fuse index:', error)
      }
    },
    [workspaceId, getWorkspaceObjectsByIds, initializedRef, objectsLoading]
  )

  const syncState = useMemo(() => {
    if (!state) return null
    const output = {} as SyncState
    for (const objectType of Object.keys(state).filter(Boolean)) {
      if (ObjectTypeMetadata[objectType]?.syncEnabled) {
        output[objectType as NativeObjectType] = {
          objectType: objectType as NativeObjectType,
          objectsNeedingSync: state[objectType].objectsNeedingSync || 0,
          currentCount: state[objectType].currentCount || 0,
          needsMoreIds: !idsCompleteRef.current.has(
            objectType as NativeObjectType
          ),
          updatedAt: state[objectType].updatedAt || null,
        }
      }
    }
    return output
  }, [state])

  const anyLoading = useMemo(() => {
    return idsLoading || objectsLoading || objectsUpdatesLoading
  }, [idsLoading, objectsLoading, objectsUpdatesLoading])

  const sync = useCallback(async () => {
    const currentState = await getDayObjectState({
      workspaceId,
    })
    if (!currentState) return

    let checkForUpdates = true
    const objectTypeNeedingIds = Object.values(NativeObjectTypes).find(
      (objectType) =>
        objectType &&
        ObjectTypeMetadata[objectType]?.syncEnabled &&
        !idsCompleteRef.current.has(objectType as NativeObjectType)
    )

    if (objectTypeNeedingIds) {
      checkForUpdates = false
      const offset =
        verificationOffsetsRef.current.get(objectTypeNeedingIds) || 0
      await handleGetObjectIds(objectTypeNeedingIds as NativeObjectType, offset)
      return
    } else {
      // Get oldest sync queue objects

      const syncQueueEntries = await getSyncQueueEntries({
        workspaceId,
      })

      const syncQueueEntriesByType = {}

      for (const entry of syncQueueEntries) {
        syncQueueEntriesByType[entry.objectType] = [
          ...(syncQueueEntriesByType[entry.objectType] || []),
          entry.objectId,
        ]
      }

      for (const objectType of Object.keys(syncQueueEntriesByType)) {
        const objectIdsToQuery = syncQueueEntriesByType[objectType]

        if ((objectIdsToQuery || []).length > 0) {
          checkForUpdates = false
          await handleGetWorkspaceObjectsByIds(
            objectType as NativeObjectType,
            objectIdsToQuery
          )
          return
        } else {
          typesCompleteRef.current.add(objectType as NativeObjectType)
        }
      }
    }

    if (checkForUpdates) {
      await getObjectsLastUpdatedAt()
      return
    }
  }, [
    handleGetObjectIds,
    workspaceId,
    handleGetWorkspaceObjectsByIds,
    getObjectsLastUpdatedAt,
  ])

  const syncInterval = useMemo(() => {
    if (!state) return 1000
    const anyNeedsMoreIds = Object.keys(state).some((objectType) => {
      return !idsCompleteRef.current.has(objectType as NativeObjectType)
    })
    if (anyNeedsMoreIds) return 1500

    const anyBackfilling = Object.values(state).some(
      (objectTypeSyncState) => objectTypeSyncState?.objectsNeedingSync > 0
    )
    if (anyBackfilling) return 1000

    return 30000
  }, [state])

  const syncProcessUnderwayRef = useRef(false)

  useEffect(() => {
    if (!workspaceId) return

    let isMounted = true
    let timeoutId: NodeJS.Timeout

    const runSync = async () => {
      if (!isMounted) return

      try {
        if (syncProcessUnderwayRef.current) return
        syncProcessUnderwayRef.current = true
        await sync()
        syncProcessUnderwayRef.current = false
      } catch (error) {
        logger.warn('Failed to sync:', error)
      } finally {
        syncProcessUnderwayRef.current = false
        // Only schedule the next sync if the component is still mounted
        if (isMounted) {
          logger.dev('Scheduling next sync')
          timeoutId = setTimeout(runSync, syncInterval)
          logger.dev({ timeoutId })
        }
      }
    }

    // Start initial sync
    if (initializedRef.current && workspaceId && !anyLoading) {
      runSync()
    }

    // Cleanup
    return () => {
      isMounted = false
      if (timeoutId) {
        logger.dev('Clearing timeout', { timeoutId })
        clearTimeout(timeoutId)
      }
    }
  }, [workspaceId, syncInterval, sync, currentUser, anyLoading])

  // Keep the initialization effect, but fix the state initialization
  useEffect(() => {
    const initHandler = async () => {
      let initialState = null
      await getDayObjectStateByType({
        workspaceId,
        objectType: 'native_contact',
      })
      initialState = await getDayObjectState({
        workspaceId,
      })
      // Initialize the state with all object types

      if (Object.keys(initialState).length > 0) {
        dispatch({
          type: 'UPDATE_SYNC_STATUS',
          payload: initialState,
        })
      }
    }

    if (!state) {
      dispatch({
        type: 'INITIALIZE_STATE',
        payload: null,
      })
      initializedRef.current = true
    }

    if (!initializedRef.current && workspaceId) {
      initHandler().catch((error) => {
        logger.error('Failed to initialize state:', error)
      })
    }

    return () => {}
  }, [workspaceId, state, currentUser])

  const resetDayObjects = useCallback(async () => {
    try {
      // Clear the state
      dispatch({ type: 'RESET_STATE', payload: null })

      // Reset initialization flag
      initializedRef.current = false

      // Clear all day objects from the database
      await clearDayObjects()
    } catch (error) {
      logger.error('Failed to reset sync state:', error)
      throw error // Re-throw to handle in UI
    }
  }, [])

  const updateObject = useCallback(
    async ({
      objectId,
      objectType,
      propertyId,
      propertyValue,
    }: {
      objectId: string
      objectType: NativeObjectType
      propertyId: string
      propertyValue: any
    }) => {
      const keyField = isUUID(propertyId) ? 'custom' : 'standard'
      const currentObjectSearchEntry = await getDayObjectSearchEntry({
        workspaceId,
        objectId,
        objectType,
      })

      const currentObject = currentObjectSearchEntry?.object

      const currentProperty =
        currentObject?.properties?.[keyField]?.[propertyId]

      const newProperty = {
        ...currentProperty,
        value: propertyValue,
      }

      const updatedObject = {
        ...currentObject,
        properties: {
          ...currentObject.properties,
          [keyField]: {
            ...(currentObject?.properties?.[keyField] || {}),
            [propertyId]: newProperty,
          },
        },
      }

      logger.dev('updateObject: Upserting updated object', {
        updatedObject,
      })

      await upsertDayObjects({
        objects: [updatedObject],
        objectType,
        workspaceId,
        synced: true,
      })
    },
    [workspaceId]
  )

  const getObjects = useCallback(
    async ({
      objectType,
      objectIds,
    }: {
      objectType: NativeObjectType
      objectIds: string[]
    }) => {
      const searchObjects = await getDayObjects({
        workspaceId,
        objectType,
        objectIds,
      })
      return searchObjects
    },
    [workspaceId]
  )

  const value = useMemo(
    () => ({
      workspaceId,
      syncState,
      resetDayObjects,
      updateObject,
      getObjects,
      resyncObject: handleResyncObject,
    }),
    [
      workspaceId,
      syncState,
      resetDayObjects,
      updateObject,
      getObjects,
      handleResyncObject,
    ]
  )

  return (
    <ObjectsContext.Provider value={value}>{children}</ObjectsContext.Provider>
  )
}

export default ObjectsProvider
